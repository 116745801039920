import React, { useState } from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThreeImages from '@components/ThreeImages';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import TapTextCard from '@components/TapTextCard';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '@components/SEO';
import Header from '@components/Header';
import Accordion from '@components/Accordion';
import ContactCTA from '@components/ContactCTA';

const CaseStudy = ({
    data: {
        page: {
            frontmatter: { title, description, cases, heroGraphic },
        },
    },
}) => {
    const [openItemIndex, setOpenItemIndex] = useState(null);

    return (
        <Layout>
            <SEO title={`Case Study`} />
            <div className={styles.caseStudyWrapper}>
                <Header
                    title={title}
                    description={description}
                    graphic={heroGraphic}
                    layout="case-study"
                />
                <div className={styles.caseStudyContent}>
                    <Grid>
                        {cases?.map(({ image, backstory, support, questions }, index) => (
                            <Row between="xs" key={index}>
                                <Col xs={12}>
                                    {/*<div className={styles.caseStudyPhoto}>*/}
                                    {/*    <Img fluid={image?.childImageSharp?.fluid} alt="adam" />*/}
                                    {/*</div>*/}
                                    <Divider />
                                </Col>
                                <Col xs={12}>
                                    <div className={styles.timeline}>
                                        <VerticalTimeline layout="1-column-left">
                                            <VerticalTimelineElement
                                                className="vertical-timeline-element--work"
                                                iconStyle={{
                                                    background: 'rgb(33, 150, 243)',
                                                    color: '#fff',
                                                }}
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'book-open']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                }
                                            >
                                                <h3 className="vertical-timeline-element-title">
                                                    Backstory
                                                </h3>
                                                {!!backstory && (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: backstory,
                                                        }}
                                                    />
                                                )}
                                            </VerticalTimelineElement>
                                            <VerticalTimelineElement
                                                className="vertical-timeline-element--work"
                                                iconStyle={{ background: '#7f50ff', color: '#fff' }}
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'user-friends']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                }
                                            >
                                                <h3 className="vertical-timeline-element-title">
                                                    What LiveYou has done for him
                                                </h3>
                                                {!!support && (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: support,
                                                        }}
                                                    />
                                                )}
                                            </VerticalTimelineElement>
                                            {questions?.map(({ title, description }, faqIndex) => (
                                                <VerticalTimelineElement
                                                    iconStyle={{
                                                        background: '#FF7F50',
                                                        color: '#fff',
                                                    }}
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={['fas', 'question']}
                                                            className={styles.fontAwesomeIcon}
                                                        />
                                                    }
                                                    key={title}
                                                >
                                                    {/*<TapTextCard*/}
                                                    {/*    title={title}*/}
                                                    {/*    content={description}*/}
                                                    {/*/>*/}
                                                    <Accordion
                                                        open={openItemIndex === faqIndex}
                                                        onToggle={open =>
                                                            setOpenItemIndex(open ? faqIndex : null)
                                                        }
                                                        title={title}
                                                        html={description}
                                                    />
                                                </VerticalTimelineElement>
                                            ))}
                                        </VerticalTimeline>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Grid>
                </div>
                <Divider />
                <Divider />
                <ContactCTA />
            </div>
        </Layout>
    );
};

export default CaseStudy;

export const query = graphql`
    query caseStudyPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: case_study_title
                description: case_study_description
                heroGraphic: case_study_hero_graphic {
                    publicURL
                }
                cases: case_study_cases {
                    image: case_study_cases_image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    backstory: case_study_cases_backstory
                    support: case_study_cases_support
                    questions: case_study_cases_questions {
                        title: case_study_cases_questions_title
                        description: case_study_cases_questions_description
                    }
                }
            }
        }
    }
`;
