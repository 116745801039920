import React from 'react';
import styles from './styles.module.scss';

const ThreeImages = ({ firstImg, secondImg, thirdImg }) => {
    return (
        <div className={styles.imagesContainer}>
            <div className={styles.imageTop}>{firstImg}</div>
            <div className={styles.imageTop}>{secondImg}</div>
            <div className={styles.imageTop}>{thirdImg}</div>
            {/*{firstImg && secondImg && (*/}
            {/*    <div className={styles.imagesBottom}>*/}
            {/*        {secondImg}*/}
            {/*        {thirdImg}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};

export default ThreeImages;
